module.exports = {
  LOGIN_USER: "LOGIN_USER",
  LOGIN_FAIL: "LOGIN_FAIL",
  LOGIN_REQUEST: "LOGIN_REQUEST",
  GET_PROFILE: "GET_PROFILE",
  GET_PROFILE_REQUEST: "GET_PROFILE_REQUEST",
  GET_PROFILE_FAIL: "GET_PROFILE_FAIL",
  USER_LOGOUT: "USER_LOGOUT",
  USER_RESET: "USER_RESET",
  PROFILE_RESET: "PROFILE_RESET",
  CHANGE_WIDTH: "CHANGE_WIDTH",

  GET_USERS: "GET_USERS",
  GET_USERS_REQUEST: "GET_USERS_REQUEST",
  GET_USERS_FAIL: "GET_USERS_FAIL",

  GET_USER_BY_LOCATION: "GET_USER_BY_LOCATION",
  GET_USER_BY_LOCATION_REQUEST: "GET_USER_BY_LOCATION_REQUEST",
  GET_USER_BY_LOCATION_FAIL: "GET_USER_BY_LOCATION_FAIL",

  GET_USER: "GET_USER",
  GET_USER_REQUEST: "GET_USER_REQUEST",
  GET_USER_FAIL: "GET_USER_FAIL",

  CREATE_USER: "CREATE_USER",
  CREATE_USER_REQUEST: "CREATE_USER_REQUEST",
  CREATE_USER_FAIL: "CREATE_USER_FAIL",

  UPDATE_USER: "UPDATE_USER",
  UPDATE_USER_REQUEST: "UPDATE_USER_REQUEST",
  UPDATE_USER_FAIL: "UPDATE_USER_FAIL",

  DELETE_USER: "DELETE_USER",
  DELETE_USER_REQUEST: "DELETE_USER_REQUEST",
  DELETE_USER_FAIL: "DELETE_USER_FAIL",

  SEND_PASSWORD_LINK: "SEND_PASSWORD_LINK",
  SEND_PASSWORD_LINK_REQUEST: "SEND_PASSWORD_LINK_REQUEST",
  SEND_PASSWORD_LINK_FAIL: "SEND_PASSWORD_LINK_FAIL",

  SET_PASSWORD: "SET_PASSWORD",
  SET_PASSWORD_REQUEST: "SET_PASSWORD_REQUEST",
  SET_PASSWORD_FAIL: "SET_PASSWORD_FAIL",

  SET_USER_PASSWORD: "SET_USER_PASSWORD",
  SET_USER_PASSWORD_REQUEST: "SET_USER_PASSWORD_REQUEST",
  SET_USER_PASSWORD_FAIL: "SET_USER_PASSWORD_FAIL",

  GET_USER_BY_TOKEN: "GET_USER_BY_TOKEN",
  GET_USER_BY_TOKEN_REQUEST: "GET_USER_BY_TOKEN_REQUEST",
  GET_USER_BY_TOKEN_FAIL: "GET_USER_BY_TOKEN_FAIL",

  UPLOAD_PHOTO: "UPLOAD_PHOTO",
  UPLOAD_PHOTO_REQUEST: "UPLOAD_PHOTO_REQUEST",
  UPLOAD_PHOTO_FAIL: "UPLOAD_PHOTO_FAIL",

  UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
  UPDATE_USER_PASSWORD_REQUEST: "UPDATE_USER_PASSWORD_REQUEST",
  UPDATE_USER_PASSWORD_FAIL: "UPDATE_USER_PASSWORD_FAIL",

  UPDATE_PROFILE: "UPDATE_PROFILE",
  UPDATE_PROFILE_REQUEST: "UPDATE_PROFILE_REQUEST",
  UPDATE_PROFILE_FAIL: "UPDATE_PROFILE_FAIL",

  DELETE_PHOTO: "DELETE_PHOTO",
  DELETE_PHOTO_REQUEST: "DELETE_PHOTO_REQUEST",
  DELETE_PHOTO_FAIL: "DELETE_PHOTO_FAIL",

  CREATE_USER_ROLE: "USER_ROLE",
  CREATE_USER_ROLE_REQUEST: "USER_ROLE_REQUEST",
  CREATE_USER_ROLE_FAIL: "USER_ROLE_FAIL",

  GET_USER_ROLES: "GET_USER_ROLES",
  GET_USER_ROLES_REQUEST: "GET_USER_ROLES_REQUEST",
  GET_USER_ROLES_FAIL: "GET_USER_ROLES_FAIL",

  GET_USER_ROLE: "GET_USER_ROLE",
  GET_USER_ROLE_REQUEST: "GET_USER_ROLE_REQUEST",
  GET_USER_ROLE_FAIL: "GET_USER_ROLE_FAIL",

  UPDATE_USER_ROLE: "UPDATE_USER_ROLE",
  UPDATE_USER_ROLE_REQUEST: "UPDATE_USER_ROLE_REQUEST",
  UPDATE_USER_ROLE_FAIL: "UPDATE_USER_ROLE_FAIL",

  DELETE_USER_ROLE: "DELETE_USER_ROLE",
  DELETE_USER_ROLE_REQUEST: "DELETE_USER_ROLE_REQUEST",
  DELETE_USER_ROLE_FAIL: "DELETE_USER_ROLE_FAIL",

  ARCHIVE_USER_ROLE: "ARCHIVE_USER_ROLE",
  ARCHIVE_USER_ROLE_REQUEST: "ARCHIVE_USER_ROLE_REQUEST",
  ARCHIVE_USER_ROLE_FAIL: "ARCHIVE_USER_ROLE_FAIL",

  ACTIVATE_USER: "ACTIVATE_USER",
  ACTIVATE_USER_REQUEST: "ACTIVATE_USER_REQUEST",
  ACTIVATE_USER_FAIL: "ACTIVATE_USER_FAIL",

  DEACTIVATE_USER: "DEACTIVATE_USER",
  DEACTIVATE_USER_REQUEST: "DEACTIVATE_USER_REQUEST",
  DEACTIVATE_USER_FAIL: "DEACTIVATE_USER_FAIL",

  CREATE_LOCATION: "CREATE_LOCATION",
  CREATE_LOCATION_REQUEST: "CREATE_LOCATION_REQUEST",
  CREATE_LOCATION_FAIL: "CREATE_LOCATION_FAIL",

  GET_LOCATIONS: "GET_LOCATIONS",
  GET_LOCATIONS_REQUEST: "GET_LOCATIONS_REQUEST",
  GET_LOCATIONS_FAIL: "GET_LOCATIONS_FAIL",

  GET_LOCATION: "GET_LOCATION",
  GET_LOCATION_REQUEST: "GET_LOCATION_REQUEST",
  GET_LOCATION_FAIL: "GET_LOCATION_FAIL",

  UPDATE_LOCATION: "UPDATE_LOCATION",
  UPDATE_LOCATION_REQUEST: "UPDATE_LOCATION_REQUEST",
  UPDATE_LOCATION_FAIL: "UPDATE_LOCATION_FAIL",

  DELETE_LOCATION: "DELETE_LOCATION",
  DELETE_LOCATION_REQUEST: "DELETE_LOCATION_REQUEST",
  DELETE_LOCATION_FAIL: "DELETE_LOCATION_FAIL",

  ARCHIVE_LOCATION: "ARCHIVE_LOCATION",
  ARCHIVE_LOCATION_REQUEST: "ARCHIVE_LOCATION_REQUEST",
  ARCHIVE_LOCATION_FAIL: "ARCHIVE_LOCATION_FAIL",


 
};
