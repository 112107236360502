import {
  LOGIN_USER,
  LOGIN_FAIL,
  LOGIN_REQUEST,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_REQUEST,
  USER_LOGOUT,
  USER_RESET,
  PROFILE_RESET,
  GET_USERS,
  GET_USERS_REQUEST,
  GET_USERS_FAIL,
  CREATE_USER,
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  UPDATE_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_REQUEST,
  DELETE_USER_FAIL,
  GET_USER,
  GET_USER_REQUEST,
  GET_USER_FAIL,
  GET_USER_BY_LOCATION,
  GET_USER_BY_LOCATION_REQUEST,
  GET_USER_BY_LOCATION_FAIL,
  SEND_PASSWORD_LINK,
  SEND_PASSWORD_LINK_FAIL,
  SEND_PASSWORD_LINK_REQUEST,
  SET_PASSWORD,
  SET_PASSWORD_FAIL,
  SET_PASSWORD_REQUEST,
  SET_USER_PASSWORD,
  SET_USER_PASSWORD_FAIL,
  SET_USER_PASSWORD_REQUEST,
  GET_USER_BY_TOKEN,
  GET_USER_BY_TOKEN_FAIL,
  GET_USER_BY_TOKEN_REQUEST,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_FAIL,
  UPLOAD_PHOTO_REQUEST,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_FAIL,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_PROFILE,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE_REQUEST,
  DELETE_PHOTO,
  DELETE_PHOTO_FAIL,
  DELETE_PHOTO_REQUEST,
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_FAIL,
  CREATE_USER_ROLE_REQUEST,
  GET_USER_ROLES,
  GET_USER_ROLES_FAIL,
  GET_USER_ROLES_REQUEST,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_FAIL,
  DELETE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_FAIL,
  UPDATE_USER_ROLE_REQUEST,
  ARCHIVE_USER_ROLE,
  ARCHIVE_USER_ROLE_FAIL,
  ARCHIVE_USER_ROLE_REQUEST,
  GET_USER_ROLE,
  GET_USER_ROLE_FAIL,
  GET_USER_ROLE_REQUEST,
  ACTIVATE_USER,
  ACTIVATE_USER_FAIL,
  ACTIVATE_USER_REQUEST,
  DEACTIVATE_USER,
  DEACTIVATE_USER_FAIL,
  DEACTIVATE_USER_REQUEST,
  CHANGE_WIDTH,

  CREATE_LOCATION,
  CREATE_LOCATION_FAIL,
  CREATE_LOCATION_REQUEST,
  UPDATE_LOCATION,
  UPDATE_LOCATION_FAIL,
  UPDATE_LOCATION_REQUEST,
  GET_LOCATION,
  GET_LOCATION_FAIL,
  GET_LOCATION_REQUEST,
  GET_LOCATIONS,
  GET_LOCATIONS_FAIL,
  GET_LOCATIONS_REQUEST,
  DELETE_LOCATION,
  DELETE_LOCATION_FAIL,
  DELETE_LOCATION_REQUEST,
  ARCHIVE_LOCATION,
  ARCHIVE_LOCATION_FAIL,
  ARCHIVE_LOCATION_REQUEST,
  

} from "../types/userTypes";

const initState = {
  users: [],
  profile: {},
  roles: [],
  role: {},
  locations: [],
  location: {},
  error: null,
  loading: false,
  drawerWidth: 250
};

const userReducer =  (state = initState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case LOGIN_USER:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };

    case CHANGE_WIDTH:
      return {
        ...state,
        drawerWidth: state.drawerWidth === 250 ? 60 : 250
      };

    case GET_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_PROFILE:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_PROFILE_FAIL:
      return {
        ...state,
        loading: false,

        error: action.payload,
      };
    case GET_USER:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case GET_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case GET_USERS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USERS:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
      };
    case GET_USERS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_BY_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_BY_LOCATION:
      return {
        ...state,
        loading: false,
        users: action.payload.data,
      };
    case GET_USER_BY_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case USER_LOGOUT:
      return {};
    case USER_RESET:
      return {
        user: {},
        users: [],
      };
    case PROFILE_RESET:
      return {
        profile: {},
      };
    case CREATE_USER:
      return {
        ...state,
        users: [...state.users, action.payload.data],
      };
    case CREATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER:
      return {
        ...state,
        users: state.users.map((user) =>
          user._id === action.payload._id ? action.payload : user
        ),
      };
    case UPDATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_USER:
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload),
      };
    case DELETE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case SEND_PASSWORD_LINK:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SEND_PASSWORD_LINK_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SEND_PASSWORD_LINK_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_PASSWORD:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case SET_USER_PASSWORD:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SET_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case SET_USER_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
      
    case GET_USER_BY_TOKEN:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case GET_USER_BY_TOKEN_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_BY_TOKEN_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case UPLOAD_PHOTO:
      return {
        ...state,
        loading: false,
        user: action.payload.userImage
      };
    case UPLOAD_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPLOAD_PHOTO_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case DELETE_PHOTO:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case DELETE_PHOTO_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_PHOTO_REQUEST:
      return {
        ...state,
        loading: true,
      };
    
    case UPDATE_USER_PASSWORD:
      return {
        ...state,
        loading: false,
        user: action.payload,
      };
    case UPDATE_USER_PASSWORD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_PROFILE:
      return {
        ...state,
        loading: false,
        profile: action.payload,
      };
    case UPDATE_PROFILE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_USER_ROLE:
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case CREATE_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case CREATE_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_ROLE:
      return {
        ...state,
        loading: false,
        role: action.payload.data,
      };
    case GET_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_USER_ROLES:
      return {
        ...state,
        loading: false,
        roles: action.payload.data,
      };
    case GET_USER_ROLES_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_USER_ROLES_REQUEST:
      return {
        ...state,
        loading: true,
      };

    case UPDATE_USER_ROLE:
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case UPDATE_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_USER_ROLE:
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case DELETE_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARCHIVE_USER_ROLE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARCHIVE_USER_ROLE:
      return {
        ...state,
        loading: false,
        role: action.payload,
      };
    case ARCHIVE_USER_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    case ACTIVATE_USER:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case ACTIVATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DEACTIVATE_USER:
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case DEACTIVATE_USER_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DEACTIVATE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_LOCATION:
      return {
        ...state,
        loading: false,
        location: action.payload.data,
      };
    case CREATE_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATION:
      return {
        ...state,
        loading: false,
        location: action.payload.data,
      };
    case GET_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case GET_LOCATIONS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_LOCATIONS:
      return {
        ...state,
        loading: false,
        locations: action.payload.data,
      };
    case GET_LOCATIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case UPDATE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case UPDATE_LOCATION:
      return {
        ...state,
        loading: false,
        location: action.payload.data,
      };
    case UPDATE_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case DELETE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case DELETE_LOCATION:
      return {
        ...state,
        loading: false,
        location: action.payload.data,
      };
    case DELETE_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    case ARCHIVE_LOCATION_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case ARCHIVE_LOCATION:
      return {
        ...state,
        loading: false,
        location: action.payload.data,
      };
    case ARCHIVE_LOCATION_FAIL:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };


    default:
      return state;
  }
};

export default userReducer;
