import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  makeStyles,
  Tooltip,
  Button,
  Grid,
  Typography,
  DialogTitle,
  Divider,
  TextField,
  FormControl,
  Paper,
  Fab,
  Tab,
  Box,
  IconButton,
  FormControlLabel,
  Checkbox,
  InputLabel,
  Select,
  MenuItem,
} from "@material-ui/core";
import {TabContext, TabList, TabPanel} from '@material-ui/lab';
import CloseIcon from "@material-ui/icons/Close";
import AddIcon from "@material-ui/icons/Add";
import { useDispatch, useSelector } from "react-redux";
import { createIncident, submitIncident, getIncidentsByUser, getCauses, getContacts, getEvents} from "../../../actions/incidentAction";
import { getLocations } from "../../../actions/usersAction";
import Feedback from "../../../utils/Feedback";
import { Autocomplete } from "@material-ui/lab";

const moment = require("moment");

const useStyles = makeStyles((theme) => ({
  dialogContent: {
    padding: theme.spacing(6),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(2),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },

  title: {
    fontSize: 13,
  },
  title2: {
    fontSize: 13,
    marginBottom: 10,
    fontWeight: "bold",

    },

  time: {
    // marginLeft: "auto",
    marginTop: 10,

    // width: 120,
    width: "fit-content",
    height: 40,
    padding: theme.spacing(1),
    // textAlign: "center",
    margin: "auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    marginRight: 5,
  },
  btn: {
    padding: theme.spacing(1.5),
    marginTop: 30,
    marginBottom: 30,
    width: 300,
    [theme.breakpoints.down("sm")]: {
        width: "50%",
      },
    [theme.breakpoints.down("xs")]: {
    width: "100%",
    }
  },

  btn2:{
    marginBottom: 30,
    marginLeft: 10
  },
  dialog: {
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      height: "100%",
    },
  },
  formControl: {
      minWidth: 760,
      [theme.breakpoints.down("sm")]: {
        minWidth: 640,
      },
      [theme.breakpoints.down("xs")]: {
        minWidth: "100%",
      }
  },
  formControl2: {
    textAlign: "left",
    minWidth: 370,
    [theme.breakpoints.down("sm")]: {
      minWidth: 310,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  formControl3: {
    textAlign: "left",
    minWidth: 240,
    [theme.breakpoints.down("sm")]: {
      minWidth: 200,
    },
    [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
    },
  },
  paper: {
      padding: theme.spacing(2),
      textAlign: "center",
      color: theme.palette.text.secondary,
  },
  [theme.breakpoints.down("sm")]: {
      width: "100%",
  },
  [theme.breakpoints.down("xs")]: {
      minWidth: "100%",
  },
  fab: {
      right: theme.spacing(-1),
  },
  tab: {
    marginTop: 10,
    marginBottom: 10,
    [theme.breakpoints.down("sm")]: {
      display:"block",
      lineHeight: 1.5,
    }
  },

  tabPanel: {
    width: 300,
    marginTop: 30,
    marginBottom: 30,
  },
  typo : {
    fontWeight: "bold",
    fontSize: 13,
  },


}));

const INIT_STATE = {
    location: undefined,
    exactLocation: "",
    date: moment().format('YYYY-MM-DD HH:mm'),
    rootCause: false,
    incidentType: [],
    worker: "",
    injury: [],
    workType: [],
    day: "",
    daysInShift: "",
    testing: "",
    rotation: "",
    sequence: "",
    contactType: [],
    directCause: [],
    causeDescription: "",
    eventType: [],
    eventDescription: "",
    injuryData: [],
};

const CreateIncidentReport = ({ openCreateIncidentReport, handleCloseCreateIncidentReport }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [state, setState] = useState(INIT_STATE);
  const [feed, setFeed] = React.useState({
    loading: false,
    open: false,
    message: "",
    success: false,
  });

  const { error, loading, contacts, causes, events } = useSelector((state) => state.incident);
  const { profile, locations } = useSelector((state) => state.users);
  const [val, setVal] = useState('1');

  const typeOfIncident = [
    {id: 1, name: "Chemical Spill or Release"},
    {id: 2, name: "Environmental"},
    {id: 3, name: "Fire/Explosion"},
    {id: 4, name: "Hazard Observation"},
    {id: 5, name: "Illness"},
    {id: 6, name: "Injury"},
    {id: 7, name: "Property Damage"},
    {id: 8, name: "Near Miss"},
    {id: 9, name: "Security"},
    {id: 10, name: "Vehicle/Equipment Damage"},
    {id: 11, name: "Workplace Violence"},
  ]

  const injuryList = [
    {id: 1, name: "CVNT"},
    {id: 2, name: "First Aid"},
    {id: 3, name: "Medical Treatment"},
    {id: 4, name: "Restricted Work Day"},
    {id: 5, name: "Lost Work Day"},
    {id: 6, name: "Fatality"},
  ]

  const workList = [
    {id: 1, name: "Civil"},
    {id: 2, name: "Structural/Crane"},
    {id: 3, name: "Piping/Mechanical"},
    {id: 4, name: "Electrical/Instr."},
    {id: 5, name: "Scaffold/Insulation"},
    {id: 6, name: "Other"},
  ]

  useEffect(() => {
    dispatch(getIncidentsByUser());
    dispatch(getLocations());
    dispatch(getContacts())
    dispatch(getCauses())
    dispatch(getEvents())
    }, [dispatch]);

  useEffect(() => {
    const abortController = new AbortController();
    if (error && error !== null) {
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: error,
        success: false,
      }));
    }
    return () => {
      abortController.abort();
    };
  }, [error]);

  const handleChange = (e) => {
    e.persist();
    setState((prevState) => ({ ...prevState, [e.target.name]: e.target.value }));
    
  };

  const handleChangeInjuryData = (index) => (e) => {
    e.persist();
    const { name, value } = e.target;
    const list = [...state.injuryData];
    list[index][name] = value;
    setState((prevState) => ({ ...prevState, injuryData: list }));
  };


  const handleAddInjury = (e) => {
    e.persist();
    const data = {
        part: '',
        comment: ''
    }
    setState((prevState) => ({ ...prevState, injuryData: [...prevState.injuryData, data] }));
  };

  const handleRemoveInjury = (index) => (e) => {
    e.persist();
    const list = [...state.injuryData];
    list.splice(index, 1);
    setState((prevState) => ({ ...prevState, injuryData: list }));
};


  const handleSave = (e) => {
    e.preventDefault();
    let data = {
        location: state.location,
        exactLocation: state.exactLocation,
        date: state.date,
        rootCause: state.rootCause,
        incidentType: [...state.incidentType],
        worker: state.worker,
        injury: [...state.injury],
        workType: [...state.workType],
        day: state.day,
        daysInShift: state.daysInShift,
        testing: state.testing,
        rotation: state.rotation,
        sequence: state.sequence,
        contactType: [...state.contactType],
        directCause: [...state.directCause],
        causeDescription: state.causeDescription,
        eventType: [...state.eventType],
        eventDescription: state.eventDescription,
        injuryData: state.injuryData,
    };
    dispatch(createIncident(data));

    setTimeout(() => {
      handleCloseCreateIncidentReport()
      dispatch(getIncidentsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Incident Report Saved Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
        location: state.location,
        exactLocation: state.exactLocation,
        date: state.date,
        time: state.time,
        rootCause: state.rootCause,
        incidentType: [...state.incidentType],
        worker: state.worker,
        injury: [...state.injury],
        workType: [...state.workType],
        day: state.day,
        daysInShift: state.daysInShift,
        testing: state.testing,
        rotation: state.rotation,
        sequence: state.sequence,
        contactType: [...state.contactType],
        directCause: [...state.directCause],
        causeDescription: state.causeDescription,
        eventType: [...state.eventType],
        eventDescription: state.eventDescription,
        injuryData: [...state.injuryData],
        path: "incident/report/view"
    };
    dispatch(submitIncident(data));
    setTimeout(() => {
      handleCloseCreateIncidentReport()
      dispatch(getIncidentsByUser());
      setState(INIT_STATE);
      setFeed((prev) => ({
        loading: false,
        open: true,
        message: "Incident Report Submitted Successfully.",
        success: true,
      }));
    }, 1500);
  };

  const handleTabChange = (event, newVal) => {
    setVal(newVal);
  };


  const handleCloseFeed = () => {
    setFeed((prevState) => ({ ...prevState, open: false }));
  };

  const isValid = () =>
    !state.date || !state.location

  return (
    <div>
      {feed.success ? (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="success"
          message={feed.message}
        />
      ) : (
        <Feedback
          handleCloseFeed={handleCloseFeed}
          open={feed.open}
          severity="error"
          message={feed.message}
        />
      )}
      <Dialog
        open={openCreateIncidentReport}
        onClose={handleCloseCreateIncidentReport}
        fullWidth
        maxWidth="md"
        className={classes.dialog}

      >
        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="center">
            <Grid item>
              <Typography variant="overline" className={classes.title}>
                Incident Report
              </Typography>
            </Grid>
            <Grid item>
              <Tooltip arrow title="Close">
                <IconButton onClick={handleCloseCreateIncidentReport}>
                  <CloseIcon />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </DialogTitle>
        <Divider />
        
        <DialogContent className={classes.dialogContent}>
          <Paper className={classes.paper} variant="outlined">
          <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={val}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} textColor="secondary" indicatorColor="secondary">
                    <Tab label="Preliminary Report" className={classes.tab} value="1"/>
                    <Tab label="Other Information"  className={classes.tab} value="2"/>
                    </TabList>
                </Box>
                <TabPanel value="1">
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>

                        <Grid item xs={12} sm={6}>
                        <TextField
                            name="reporter"
                            fullWidth
                            variant="outlined"
                            label="Reporter"
                            value={profile?.displayname || ""}
                            disabled
                            required
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} >
                        <FormControl variant="outlined" className={classes.formControl2} required>
                            <InputLabel id="demo-simple-select-outlined-label">
                                Site
                            </InputLabel>
                            <Select
                            labelId="demo-simple-select-outlined-label"
                            id="demo-simple-select-outlined"
                            fullWidth
                            value={state.location || ""}
                            name="location"
                            onChange={handleChange}
                            label="Site"
                            >
                            <MenuItem value={null}>
                                <em>None</em>
                            </MenuItem>
                            {locations &&
                                locations.map((location) => (
                                <MenuItem key={location._id} value={location._id}>
                                    {location.displayname}
                                </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        </Grid>
                        
                        <Grid item xs={12} sm={6}>
                        <TextField
                            name="exactLocation"
                            fullWidth
                            variant="outlined"
                            label="Exact Location"
                            onChange={handleChange}
                            value={state.exactLocation || ""}
                            />
                        </Grid>

                        <Grid item xs={12} sm={6}>
                        <TextField
                            name="date"
                            fullWidth
                            variant="outlined"
                            label="Date & Time"
                            onChange={handleChange}
                            value={state.date || moment().format('YYYY-MM-DD HH:mm')}
                            required
                            type="datetime-local"   
                        />
                        </Grid>

                        <Grid item xs={10} sm={5} md={5} >
                        <Typography variant="subtitle2" align="left">
                            Root Cause Required?
                        </Typography>
                        </Grid>
                        <Grid item xs={2} sm={1} md={1}>
                            <FormControlLabel
                                control={
                                <Checkbox
                                    checked={state.rootCause || false}
                                    name="rootCause"
                                    color="primary"
                                    onChange={(e, value) => {
                                    e.persist()
                                    setState((prevState) =>({ ...prevState, rootCause: value }))
                                    }}
                                />
                                }
                            />       
                        </Grid> 
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <Autocomplete 
                                    multiple
                                    id="incidentType"
                                    options={typeOfIncident}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name} 
                                    onChange={(e, value) => setState((prevState) => ({ ...prevState, incidentType: value }))} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Primary Type of Incident" />} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" align="left">
                                If Injury or Illness, List Worker's Name(s) & Implication
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <TextField
                                name="worker"
                                fullWidth
                                variant="outlined"
                                label="Worker Name"
                                onChange={handleChange}
                                value={state.worker || ""}
                                multiline
                                />
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <Autocomplete 
                                    multiple
                                    id="injury"
                                    options={injuryList}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name} 
                                    onChange={(e, value) => setState((prevState) => ({ ...prevState, injury: value }))} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Implication" />} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <Autocomplete 
                                    multiple
                                    id="workType"
                                    options={workList}
                                    getOptionSelected={(option, value) => option.name === value.name}
                                    getOptionLabel={(option) => option.name} 
                                    onChange={(e, value) => setState((prevState) => ({ ...prevState, workType: value }))} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Type of Work" />} 
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                            <FormControl variant="outlined" className={classes.formControl2}>
                                <InputLabel id="day">
                                    Day of Week
                                </InputLabel>
                                <Select
                                    labelId="day"
                                    fullWidth
                                    value={state.day || ""}
                                    name="day"
                                    onChange={handleChange}
                                    variant="outlined"
                                >
                                    <MenuItem value="Sunday">Sunday</MenuItem>
                                    <MenuItem value="Monday">Monday</MenuItem>
                                    <MenuItem value="Tuesday">Tuesday</MenuItem>
                                    <MenuItem value="Wednesday">Wednesday</MenuItem>
                                    <MenuItem value="Thursday">Thursday</MenuItem>
                                    <MenuItem value="Friday">Friday</MenuItem>
                                    <MenuItem value="Saturday">Saturday</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={4}>
                        <TextField
                            name="daysInShift"
                            fullWidth
                            variant="outlined"
                            label="Days Into Shifts"
                            onChange={handleChange}
                            value={state.daysInShift || ""}
                            type="number"
                            />
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <FormControl variant="outlined" className={classes.formControl3}>
                                <InputLabel id="testing">
                                    Post Incident Testing
                                </InputLabel>
                                <Select
                                    labelId="testing"
                                    fullWidth
                                    value={state.testing || ""}
                                    name="testing"
                                    onChange={handleChange}
                                    variant="outlined"
                                >
                                    <MenuItem value="Yes">Yes</MenuItem>
                                    <MenuItem value="No">No</MenuItem>
                                    <MenuItem value="N/A">N/A</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                        <TextField
                            name="rotation"
                            fullWidth
                            variant="outlined"
                            label="Shift Rotation"
                            onChange={handleChange}
                            value={state.rotation || ""}
                            />
                        </Grid>
                    
                    </Grid>
                </TabPanel>
                <TabPanel value="2">
                    <Grid container justifyContent="center" alignItems="center" spacing={3}>
                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="sequence"
                                fullWidth
                                variant="outlined"
                                label="Sequence of Events"
                                onChange={handleChange}
                                value={state.sequence || ""}
                                multiline
                                helperText="(Pre-Incident, Incident, Post-Incident) - Attach Additional Documents for More Information"
                                />
                        </Grid>
                        <Typography variant="body2" align="center" color="primary">
                            NOTE: You have to save before you can attach additional documents
                        </Typography>
                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete 
                                    multiple
                                    id="contactTypes"
                                    options={contacts}
                                    getOptionSelected={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) => option.name} 
                                    onChange={(e, value) => setState((prevState) => ({ ...prevState, contactType: value }))} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label=" Primary Type of Contact" />} 
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete 
                                    multiple
                                    id="directCause"
                                    options={causes}
                                    getOptionSelected={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) => option.name} 
                                    onChange={(e, value) => setState((prevState) => ({ ...prevState, directCause: value }))} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Immediate Direct Causes"/>} 
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="causeDescription"
                                fullWidth
                                variant="outlined"
                                label="Direct Cause Description"
                                onChange={handleChange}
                                value={state.causeDescription || ""}
                                multiline
                                helperText="Provide Description of Each Selected Direct Cause"
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <FormControl className={classes.formControl}>
                                <Autocomplete 
                                    multiple
                                    id="eventType"
                                    options={events}
                                    getOptionSelected={(option, value) => option._id === value._id}
                                    getOptionLabel={(option) => option.name} 
                                    onChange={(e, value) => setState((prevState) => ({ ...prevState, eventType: value }))} 
                                    renderInput={(params) => 
                                    <TextField {...params} variant="outlined" label="Type of Event"/>} 
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} sm={12}>
                            <TextField
                                name="eventDescription"
                                fullWidth
                                variant="outlined"
                                label="Event Type Description"
                                onChange={handleChange}
                                value={state.eventDescription || ""}
                                multiline
                                helperText="Provide Description of Each Selected Event Type"
                                />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Divider />
                        </Grid>
                        <Grid item xs={12} sm={12}>
                            <Typography variant="subtitle2" align="center">
                                INJURY STATISTICAL DATA
                            </Typography>
                        </Grid>
                        {state.injuryData && state.injuryData.length > 0 && (
                        <Grid item xs={12} sm={12}>
                            <Paper className={classes.paper} variant="outlined" square>
                                <Typography variant="subtitle2" align="center">
                                    Select All That Apply and Provide a Brief Description of Injury
                                </Typography>
                                <img src="/img/body-parts.jpg" alt="" width="200"/>
                              
                                <br />
                                {state.injuryData.map((item, index) => (
                                <Grid container justifyContent="center" alignItems="center" spacing={2} key={index}>
                                    <Grid item xs={5} sm={4} md={4}>
                                        <FormControl variant="outlined" className={classes.formControl3} size="small">
                                            <InputLabel id="part">
                                                Body Part
                                            </InputLabel>
                                            <Select
                                                labelId="part"
                                                fullWidth
                                                value={item.part || ""}
                                                name="part"
                                                onChange={handleChangeInjuryData(index)}
                                                variant="outlined"
                                                required
                                            >
                                                <MenuItem value="Head/Skull/Scalp">Head/Skull/Scalp</MenuItem>
                                                <MenuItem value="Shoulder">Shoulder</MenuItem>
                                                <MenuItem value="Back">Back</MenuItem>
                                                <MenuItem value="Elbow">Elbow</MenuItem>
                                                <MenuItem value="Abdomen/Groin">Abdomen/Groin</MenuItem>
                                                <MenuItem value="Knee">Knee</MenuItem>
                                                <MenuItem value="Ankle">Ankle</MenuItem>
                                                <MenuItem value="Face/Ears">Face/Ears</MenuItem>
                                                <MenuItem value="Eyes">Eyes</MenuItem>
                                                <MenuItem value="Hand">Hand</MenuItem>
                                                <MenuItem value="Arm">Arm</MenuItem>
                                                <MenuItem value="Wrist">Wrist</MenuItem>
                                                <MenuItem value="Leg">Leg</MenuItem>
                                                <MenuItem value="Foot">Foot</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={7} sm={7} md={7}>
                                        <TextField
                                            name="comment"
                                            fullWidth
                                            variant="outlined"
                                            label="Comment"
                                            onChange={handleChangeInjuryData(index)}
                                            value={item.comment}
                                            size="small"
                                            multiline
                                        />
                                    </Grid>   
                                    <Grid item xs={2} sm={1} md={1}>
                                        <IconButton color="secondary" onClick={handleRemoveInjury(index)}>
                                            <CloseIcon />
                                        </IconButton>
                                    </Grid>
                                  

                                </Grid>
                                ))}

                            </Paper>
                        </Grid>
                        )}
                        <Typography variant="subtitle2" align="left">
                            Click to Add Body Parts Affected
                        </Typography>
                        <Fab color="primary" aria-label="add" size="medium" onClick={handleAddInjury} className={classes.fab}>
                            <AddIcon />
                        </Fab>
                        
                    </Grid>    
                               
                    <br />
                    <br />
                    <Typography align="center">
                        <Button
                        variant="contained"
                        size="large"
                        onClick={handleSave}
                        className={classes.btn2}
                        disabled={isValid() || loading}
                        >
                        {loading ? "Loading..." : "Save"}
                        </Button>
                        <Button
                        color="primary"
                        variant="contained"
                        size="large"
                        onClick={handleSubmit}
                        className={classes.btn2}
                        disabled={isValid() || loading}
                        >
                        {loading ? "Loading..." : "Save & Submit"}
                        </Button>
                    </Typography>
                </TabPanel>
            </TabContext>
          </Box>
          </Paper>


        </DialogContent>
      </Dialog>
    </div>
  );
};

export default CreateIncidentReport;
