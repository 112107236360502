import axios from "axios";
import {
  LOGIN_FAIL,
  LOGIN_USER,
  LOGIN_REQUEST,
  GET_PROFILE,
  GET_PROFILE_FAIL,
  GET_PROFILE_REQUEST,
  USER_LOGOUT,
  USER_RESET,
  PROFILE_RESET,
  GET_USERS,
  GET_USERS_REQUEST,
  GET_USERS_FAIL,
  GET_USER_BY_LOCATION,
  GET_USER_BY_LOCATION_REQUEST,
  GET_USER_BY_LOCATION_FAIL,
  CREATE_USER,
  CREATE_USER_REQUEST,
  CREATE_USER_FAIL,
  UPDATE_USER,
  UPDATE_USER_REQUEST,
  UPDATE_USER_FAIL,
  DELETE_USER,
  DELETE_USER_REQUEST,
  DELETE_USER_FAIL,
  GET_USER,
  GET_USER_REQUEST,
  GET_USER_FAIL,
  SEND_PASSWORD_LINK,
  SEND_PASSWORD_LINK_FAIL,
  SEND_PASSWORD_LINK_REQUEST,
  SET_PASSWORD,
  SET_PASSWORD_FAIL,
  SET_PASSWORD_REQUEST,
  SET_USER_PASSWORD,
  SET_USER_PASSWORD_FAIL,
  SET_USER_PASSWORD_REQUEST,
  GET_USER_BY_TOKEN,
  GET_USER_BY_TOKEN_FAIL,
  GET_USER_BY_TOKEN_REQUEST,
  UPLOAD_PHOTO,
  UPLOAD_PHOTO_FAIL,
  UPLOAD_PHOTO_REQUEST,
  UPDATE_USER_PASSWORD,
  UPDATE_USER_PASSWORD_FAIL,
  UPDATE_USER_PASSWORD_REQUEST,
  UPDATE_PROFILE_REQUEST,
  UPDATE_PROFILE_FAIL,
  UPDATE_PROFILE,
  DELETE_PHOTO,
  DELETE_PHOTO_REQUEST,
  DELETE_PHOTO_FAIL,
  CREATE_USER_ROLE,
  CREATE_USER_ROLE_REQUEST,
  CREATE_USER_ROLE_FAIL,
  GET_USER_ROLE,
  GET_USER_ROLE_REQUEST,
  GET_USER_ROLE_FAIL,
  UPDATE_USER_ROLE,
  UPDATE_USER_ROLE_REQUEST,
  UPDATE_USER_ROLE_FAIL,
  DELETE_USER_ROLE,
  DELETE_USER_ROLE_REQUEST,
  DELETE_USER_ROLE_FAIL,
  ARCHIVE_USER_ROLE,
  ARCHIVE_USER_ROLE_REQUEST,
  ARCHIVE_USER_ROLE_FAIL,
  GET_USER_ROLES,
  GET_USER_ROLES_REQUEST,
  GET_USER_ROLES_FAIL,
  ACTIVATE_USER,
  ACTIVATE_USER_REQUEST,
  ACTIVATE_USER_FAIL,
  DEACTIVATE_USER,
  DEACTIVATE_USER_REQUEST,
  DEACTIVATE_USER_FAIL,
  CREATE_LOCATION,
  CREATE_LOCATION_REQUEST,
  CREATE_LOCATION_FAIL,
  GET_LOCATION,
  GET_LOCATION_REQUEST,
  GET_LOCATION_FAIL,
  UPDATE_LOCATION,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_FAIL,
  DELETE_LOCATION,
  DELETE_LOCATION_REQUEST,
  DELETE_LOCATION_FAIL,
  GET_LOCATIONS,
  GET_LOCATIONS_REQUEST,
  GET_LOCATIONS_FAIL,
  ARCHIVE_LOCATION,
  ARCHIVE_LOCATION_REQUEST,
  ARCHIVE_LOCATION_FAIL,


} from "../types/userTypes";

const token = localStorage.getItem("token:data");

export const loginUser = ({ email, password }) => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/login`;
    dispatch({
      type: LOGIN_REQUEST,
    });
    const { data } = await axios.post(URL, { email, password });
    dispatch({
      type: LOGIN_USER,
      payload: data,
    });
    localStorage.setItem("token:data", data.token);
    return await data;
  } catch (error) {
    dispatch({
      type: LOGIN_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getProfile = () => async (dispatch) => {
  try {
    const URL = `/api/v1/auth/profile`;
    dispatch({
      type: GET_PROFILE_REQUEST,
    });
    if (token !== undefined) {
      const res = await axios.get(URL, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      dispatch({
        type: GET_PROFILE,
        payload: res.data.data,
      });
      return res.data.data;
    }
  } catch (error) {
    dispatch({
      type: GET_PROFILE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getUsers = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USERS_REQUEST,
    });
    const URL = `/api/v1/admin/user`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USERS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USERS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getUsersByLocation = (location) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BY_LOCATION_REQUEST,
    });
    const URL = `/api/v1/admin/user/location/${location}`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USER_BY_LOCATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_BY_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  };
};

export const getUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_REQUEST,
    });
    const URL = `/api/v1/admin/user/${id}`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_FAIL,
      payload:
        error.response && error.response.data.error

          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};


export const createUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_USER_REQUEST,
    });
    const URL = `/api/v1/admin/user/register`;
    const { data } = await axios.post(URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateUser = (user) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_REQUEST,
    });
    const URL = `/api/v1/admin/user/update/${user.id}`;
    const { data } = await axios.put(URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_FAIL,
      payload:

        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateProfile = (user) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_PROFILE_REQUEST,
    });
    const URL = `/api/v1/user/updateProfile`;
    const { data } = await axios.put(URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_PROFILE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_PROFILE_FAIL,
      payload:

        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_REQUEST,
    });
    const URL = `/api/v1/admin/user/${id}`;
    const { data } = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_FAIL,
      payload:

        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const sendPasswordLink = (email) => async (dispatch) => {
  try {
    dispatch({
      type: SEND_PASSWORD_LINK_REQUEST,
    });
    const URL = `/api/v1/admin/user/resendLink`;
    const { data } = await axios.post(URL, email, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    dispatch({
      type: SEND_PASSWORD_LINK,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: SEND_PASSWORD_LINK_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request 
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getUserByToken = (token) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_BY_TOKEN_REQUEST,
    });
    const URL = `/api/v1/auth/user/${token}`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USER_BY_TOKEN,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_BY_TOKEN_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};


export const setPassword = (user) => async (dispatch) => {
  try {
    dispatch({
      type: SET_PASSWORD_REQUEST,
    });
    const URL = `/api/v1/auth/setPassword/${user.passwordToken}`;
    const { data } = await axios.put(URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: SET_PASSWORD,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: SET_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const setUserPassword = (user) => async (dispatch) => {
  try {
    dispatch({
      type: SET_USER_PASSWORD_REQUEST,
    });
    const URL = `/api/v1/admin/user/setPassword/${user.id}`;
    const { data } = await axios.put(URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });

    dispatch({
      type: SET_USER_PASSWORD,
      payload: data
    });
  } catch (error) {
    dispatch({
      type: SET_USER_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};



export const updateUserPassword = (user) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_PASSWORD_REQUEST,
    });
    const URL = `/api/v1/user/updatePassword/`;
    const { data } = await axios.put(URL, user, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_USER_PASSWORD,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_PASSWORD_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.message,
    });
  }
};

export const uploadPhoto = (formData) => async (dispatch) => {
  try {
    dispatch({
      type: UPLOAD_PHOTO_REQUEST,
    });
    const URL = `/api/v1/user/uploadPhoto`;
    const { data } = await axios.put(URL, formData, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPLOAD_PHOTO,
      payload: data.userImage,
    });
  } catch (error) {
    dispatch({
      type: UPLOAD_PHOTO_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error 
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deletePhoto = () => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_PHOTO_REQUEST,
    });
    const URL = `/api/v1/user/deletePhoto`;
    const { data } = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_PHOTO,
      payload: data.userImage,
    });
  } catch (error) {
    dispatch({
      type: DELETE_PHOTO_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const createRole = (role) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_USER_ROLE_REQUEST,
    });
    const URL = `/api/v1/admin/role`;
    const { data } = await axios.post(URL, role, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_USER_ROLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getRoles = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_ROLES_REQUEST,
    });
    const URL = `/api/v1/admin/role`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USER_ROLES,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ROLES_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getRole = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_USER_ROLE_REQUEST,
    });
    const URL = `/api/v1/admin/role/${id}`;
    const { data } = await axios.get(URL, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_USER_ROLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateRole = (role) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_USER_ROLE_REQUEST,
    });
    const URL = `/api/v1/admin/role/${role.id}`;
    const { data } = await axios.put(URL, role, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_USER_ROLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteRole = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_USER_ROLE_REQUEST,
    });
    const URL = `/api/v1/admin/role/${id}`;
    const { data } = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_USER_ROLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request 
          ? error.request.data.error
          : error.message,
    });
  }
};

export const archiveRole = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ARCHIVE_USER_ROLE_REQUEST,
    });
    const URL = `/api/v1/admin/role/archive/${id}`;
    const { data } = await axios.put(URL, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: ARCHIVE_USER_ROLE,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ARCHIVE_USER_ROLE_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};


export const activateUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ACTIVATE_USER_REQUEST,
    });
    const URL = `/api/v1/admin/user/activate/${id}`;
    const { data } = await axios.put(URL, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      }
    });
    
    dispatch({
      type: ACTIVATE_USER,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ACTIVATE_USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request 
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deactivateUser = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DEACTIVATE_USER_REQUEST,
    });
    const URL = `/api/v1/admin/user/deactivate/${id}`;
    const { data } = await axios.put(URL, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DEACTIVATE_USER,
      payload: data,
    });

  } catch (error) {
    dispatch({
      type: DEACTIVATE_USER_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const createLocation = (location) => async (dispatch) => {
  try {
    dispatch({
      type: CREATE_LOCATION_REQUEST,
    });
    const URL = `/api/v1/admin/location`;
    const { data } = await axios.post(URL, location, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: CREATE_LOCATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: CREATE_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getLocations = () => async (dispatch) => {
  try {
    dispatch({
      type: GET_LOCATIONS_REQUEST,
    });
    const URL = `/api/v1/admin/location`;
    const { data } = await axios.get(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_LOCATIONS,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LOCATIONS_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const getLocation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LOCATION_REQUEST,
    });
    const URL = `/api/v1/admin/location/${id}`;
    const { data } = await axios.get(URL, { 
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: GET_LOCATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: GET_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const updateLocation = (location) => async (dispatch) => {
  try {
    dispatch({
      type: UPDATE_LOCATION_REQUEST,
    });
    const URL = `/api/v1/admin/location/${location.id}`;
    const { data } = await axios.put(URL, location, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: UPDATE_LOCATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: UPDATE_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};

export const deleteLocation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: DELETE_LOCATION_REQUEST,
    });
    const URL = `/api/v1/admin/location/${id}`;
    const { data } = await axios.delete(URL, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: DELETE_LOCATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: DELETE_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request 
          ? error.request.data.error
          : error.message,
    });
  }
};

export const archiveLocation = (id) => async (dispatch) => {
  try {
    dispatch({
      type: ARCHIVE_LOCATION_REQUEST,
    });
    const URL = `/api/v1/admin/location/archive/${id}`;
    const { data } = await axios.put(URL, null, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
    dispatch({
      type: ARCHIVE_LOCATION,
      payload: data,
    });
  } catch (error) {
    dispatch({
      type: ARCHIVE_LOCATION_FAIL,
      payload:
        error.response && error.response.data.error
          ? error.response.data.error
          : error.request
          ? error.request.data.error
          : error.message,
    });
  }
};


export const logout = () => async (dispatch) => {
  localStorage.removeItem("token:data");

  dispatch({
    type: USER_LOGOUT,
  });
  dispatch({
    type: USER_RESET,
  });
  dispatch({
    type: PROFILE_RESET,
  });
};
